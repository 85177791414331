<template>
  <div class="main-wrapper">
    <div class="page-wrapper full-page">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  // background: url('~@/assets/images/auth-bg.png') no-repeat left center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
